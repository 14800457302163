import { template as template_45021516166c4f2cafb7d83e3f3750a0 } from "@ember/template-compiler";
const WelcomeHeader = template_45021516166c4f2cafb7d83e3f3750a0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
