import { template as template_92b56971a01f4b9c85bdb3b360d7555e } from "@ember/template-compiler";
const FKLabel = template_92b56971a01f4b9c85bdb3b360d7555e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
